.sec3 {
  background: url('../../img/Bg_Schedule_Section2_LAnding.jpg') no-repeat top
    center;
  background-size: cover;

  .schedule {
    padding-top: 5vw;
    padding-bottom: 4vw;
    text-align: center;

    .watch {
      p {
        margin-bottom: 1vw;
        span {
          font-family: SemiBold;
        }
      }
    }

    @media screen and (max-width: $mobile) {
      padding-left: 0;
      padding-right: 0;
      .watch {
        p {
          margin-bottom: 5vw;
        }
      }
    }
  }
}
