/* Floating Main */
@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-12px);
  }
  100% {
    transform: translatey(0px);
  }
}

.floating_main {
  position: fixed;
  z-index: 99;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
#floating_hover {
  background-image: url('../../img/Floatingbutton.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 3vw;
  height: 10vw;
  transition: 0.1s;
  @media screen and (max-width: $mobile) {
    width: 11vw;
    height: 35vw;
  }
}

// .floating_main:hover #floating_hover {
//     background-image: url('../img/floating_mainwebsite_hover.png');
//     background-repeat: no-repeat;
// }

.anim {
  animation: float 3s infinite;
}
