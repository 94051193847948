// #teams
#teams {
	text-align: center;
	margin: auto;
	background-image: url('../../img/Bg_Pattern_Overlay.jpg');
	background-size: cover;
	background-position: top;

	// .title{
	// 	background-image: url('../img/Bg_Image_Teamsdetails.jpg');
	// 	background-size: cover;
	// }

	// teams-list
	.teams-logo {
		text-align: center;
		background-color: white;
		padding: 1vw 0;
		ul {
			padding: 0;
			&.nav {
				display: block;
				overflow: hidden;
				white-space: initial;
			}
		}
		li {
			position: relative;
			display: inline-block;
			width: calc(100% / 6);
			margin: 1% 2%;

			a {
				transition: all 0.2s ease-in-out;

				img {
					// width: 150px;
					// filter: opacity(.5);
				}

				.overlay_teams {
					width: 97%;
					height: 97%;
					position: absolute;
					top: 1%;
					background: rgba(0, 0, 0, 0.5);
					left: 2%;
					border-radius: 12px;
				}

				&.active,
				&:hover {
					.overlay_teams {
						background: transparent;
					}

					.evos {
						filter: drop-shadow(0 4px 10px #00cee0);
					}
					.ggwp {
						filter: drop-shadow(0 4px 10px #ffae00);
					}
					.btr {
						filter: drop-shadow(0 4px 10px #ff1800);
					}
					.ses {
						filter: drop-shadow(0 4px 10px #41a0f1);
					}
					.dg {
						filter: drop-shadow(0 4px 10px rgba(0, 0, 0, 0.5));
					}
					.rrq {
						filter: drop-shadow(0 4px 10px #000);
					}
					.hnt {
						filter: drop-shadow(0 4px 10px #ffd631);
					}
				}
			}
		}
	}

	// team-detail
	.header-detail {
		height: 5vh;
		width: 100%;
		// margin-top: 10vh;
		background-color: #c6c6c6;
	}
	.team-detail {
		background-color: white;
		padding: 1vw 0;
		.team-name {
			border: 1px solid #707070;
			text-align: center;
			margin: auto;
			width: 80%;
			display: flex;
			align-items: center;

			img {
				width: 25%;
				// margin: -0.1% 0 0 0;
			}
			p {
				color: #022a99;
				padding: 0 2vw;
				width: 100%;
				font-size: 2.3vw;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				font-family: Bold;
			}
		}

		.team-player {
			ul {
				padding: 3vh 0 0 0;
				li {
					border: 1px solid #707070;
					list-style-type: none;
					margin: 0 auto;
					margin-bottom: 5%;
					text-align: left;
					display: block;
					width: 80%;
					background-color: #dedede;
					display: flex;
					// border: 1px solid #c9c9c9;
					position: relative;
					border-radius: 10px;

					@media screen and (max-width: $mobile) {
						font-size: small;

						.player-picture {
							width: 40%;
						}
					}

					.player-info {
						display: block;
						margin: auto 0;
						position: relative;
						width: 100%;
						height: 100%;
						align-items: center;
						font-size: larger;
						color: black;
						font-weight: bold;
						display: flex;
						align-items: center;
						justify-content: space-between;
						text-align: center;
						padding: 0 5%;

						@media screen and (max-width: $mobile) {
							flex-direction: column;
							align-items: flex-start;
							text-align: left;

							.team,
							.name {
								border-bottom: 0.5px solid black;
								padding: 3px 0;
							}
						}

						&__container {
							flex: 1;
							display: flex;
							flex-direction: column;

							.team {
								font-family: Bold;
							}
						}

						.name {
							flex: 1;
						}
						.role {
							@media screen and (max-width: $mobile) {
								display: none;
							}
							&.mobile {
								display: none;
								padding: 3px 0;
								@media screen and (max-width: $mobile) {
									display: block;
								}
							}
						}
					}

					img {
						position: relative;
						//width: 15%;
						margin-left: 8px;
					}
					&:before {
						content: '';
						width: 8px;
						height: 100%;
						background: #feae00;
						position: absolute;
						z-index: 1;
						border-top-left-radius: 10px;
						border-bottom-left-radius: 10px;
					}
				}
			}
		}
	}

	@media screen and (max-width: 767px) {
		margin: 0;
		width: 100%;

		.container {
			padding: 0;
		}

		.title {
			background-image: url('../../img/mobile/Bg_Teamsdetail_Mobile.jpg');
			background-size: cover;
		}

		.header-detail {
			margin-top: 0;
		}

		.teams-logo {
			position: relative;
			text-align: center;
			// padding: 5vh 0;
			ul {
				padding: 0;
				white-space: nowrap;
				overflow-x: scroll;
				width: 90%;
				padding: 5vw 0;
				margin: 0 auto;

				&.nav {
					display: inline-block;
					white-space: nowrap;
					overflow-x: scroll;
				}
				li {
					position: relative;
					display: inline-block;
					width: 41%;
					margin: 0;
					margin-right: 4%;
					img {
						width: 100%;
					}

					&:last-child {
						margin-right: 0;
					}
				}
			}
		}
		.header {
			// height: 0;
		}
		// team-detail
		.header-detail {
			height: 5vh;
			margin-top: 0;
		}

		.tab-content {
			// padding: 0 3vw;
		}

		.team-detail {
			padding: 3vh 5vw;
			.team-name {
				margin: 0 0 10%;
				width: 100%;
				img {
					width: 35%;
					margin: 0;
				}
				p {
					width: 100%;
					font-size: 4vw;
				}
			}

			.team-player {
				ul {
					padding: 0;
					li {
						width: 100%;
					}
				}
			}
		}
	}
}
