@font-face {
  font-family: Bold;
  src: url('../../fonts/MONTSERRAT-BOLD.TTF');
}
@font-face {
  font-family: Light;
  src: url('../../fonts/MONTSERRAT-LIGHT.TTF');
}
@font-face {
  font-family: Regular;
  src: url('../../fonts/MONTSERRAT-REGULAR.TTF');
}
@font-face {
  font-family: SemiBold;
  src: url('../../fonts/MONTSERRAT-SEMIBOLD.TTF');
}
