.sec5{
	background: #fff;
	padding-top: 5vw;
	padding-bottom: 5vw;

	@media screen and (max-width: $mobile) {
		padding: 7vw 4vw;
	}

	.team_headline{
		display: flex;
		align-items: center;
		text-align: left;

		.box{
			width: calc(100%/2);

			.title{
				color: #002b9f;
				text-transform: capitalize;
				font-family: Bold;
				font-size: 3vw;
				line-height: 1;
				margin-bottom: 1vw;
			}

			p{
				margin-bottom: 3vw;
				padding-right: 4vw;
			}

			&:last-child{
				margin-left: 1vw;
			}
		}

		@media screen and (max-width: $mobile) {
			text-align: center;
			flex-direction: column-reverse;
			.box{
				width: 100%;

				.title{
					font-size: 8vw;
					// padding-right: 10vw;
					margin-bottom: 4vw;
				}

				p{
					margin-bottom: 8vw;
					padding-right: 4vw;
				}

				&:last-child{
					margin-left: 0;
					margin-bottom: 5vw;
				}
			}
		}
	}

}