#standings {
  .detail {
    .standings-wrapper {
      background: #fff;
      padding: 0 5vw 1vw;

      table {
        color: #6b6b6b;
        font-family: SemiBold;
        thead {
          tr th {
            border-bottom: 2px solid #6b6b6b;
          }
        }
        .team_name {
          width: 40%;
          text-align: left;
          img {
            display: block;
            width: 30%;
            margin-right: 1vw;
          }

          &__name {
            width: 65%;
          }
        }

        tbody {
          .team_name {
            width: 100%;
            display: flex;
            align-items: center;
          }

          .table-data {
            vertical-align: middle;
          }
        }
      }
    }

    @media screen and (max-width: $mobile) {
      padding: 0;
      background: #fff;
      .standings-wrapper {
        overflow-x: auto;
        padding: 0 0 4vw;
        margin: 0 4vw;

        table {
          font-size: 3.5vw;

          .team_name {
            width: 45vw;
            padding: 3vw 2vw;
            text-align: center;

            img {
              width: 30%;
              margin-right: 2vw;
            }
            span {
              width: 68%;
              display: block;
            }
          }
          th,
          td {
            padding: 3vw 4vw;
          }

          tr th,
          tr td {
            &:first-child {
              padding: 3vw 0;
            }
          }

          tbody {
            .team_name {
              display: flex;
              align-items: center;
              width: 45vw;
              font-size: 2vw;
              text-align: left;
            }
          }
        }
      }
    }
  }
}
