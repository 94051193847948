.w_pop {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: rgba(9, 18, 34, 1);
    // background: #a20000;
    z-index: 9999;
    display: none;
    overflow: hidden;

    // landscape mode
	@media screen and (max-width: $mobile) and (min-aspect-ratio: 13/9) {
        display: block;
    }

    .content {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);

        .w_pop_img {
            width: 25vw;
            height: 16vw;
            margin: auto;
            background: url('https://web.cdn.id.garenanow.com/webid/WEBDEV/scape.png') no-repeat;
            background-size: contain;
            animation: rotateA 1.5s ease infinite alternate;
        }

        .w_tips {
            width: 60%;
            font-size: 2vw;
            color: #FFFFFF;
            text-align: center;
            margin: 6vw auto 0;
        }
    }
}

@keyframes rotateA {
    0% {
        transform: rotate(0deg);
    }
    30% {
        transform: rotate(0deg);
    }
    70% {
        transform: rotate(-90deg);
    }
    100% {
        transform: rotate(-90deg);
    }
}
