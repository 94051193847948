#footer {
	background: #292929;
	color: #d6d6d6;

	.sponsor {
		justify-content: center;
		align-items: center;
		background: white;
		background-size: auto;
		color: black;
		bottom: 0;
		width: 100%;
		left: 0;
		right: 0;
		margin: 0 auto;
		background-size: 100%;
		height: 4.5vw;

		@media screen and (max-width: $mobile) {
			height: 9vw;
		}
		.list_sponsor {
			display: flex;
			gap: 1%;
			padding-right: 1%;
			justify-content: center;
			align-items: center;
			// margin-left: 1vw;
			height: 100%;

			a {
				img {
					width: 15vw;
					margin: 0;

					&.xl {
						width: 10vw;
					}

					@media screen and (max-width: $mobile) {
						width: 25vw;
						&.xl {
							width: 30vw;
						}
					}
				}
			}
		}
	}

	.container {
		padding-top: 3vw;
		padding-bottom: 3vw;

		.top_footer {
			border-bottom: 1px solid #fff;
			p {
				font-family: SemiBold;
				position: relative;
				display: inline-block;
				padding-bottom: 0.8vw;
				font-size: 1.2vw;

				&:after {
					content: '';
					height: 3px;
					width: 100%;
					background: #fff;
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					margin: 0 auto;
				}

				@media screen and (max-width: $mobile) {
					font-size: 4vw;
					&:after {
						height: 2px;
					}
				}
			}
			.socmed {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 50%;
				margin: 1.5vw auto 2vw;

				@media screen and (max-width: $mobile) {
					margin: 5vw auto;
				}

				a {
					margin-right: 3vw;
					cursor: pointer;

					@media screen and (max-width: $mobile) {
						margin-right: 5vw;
					}

					&:last-child {
						margin-right: 0;
					}

					.icon-socmed {
						width: 2.2vw;
						height: 2.2vw;

						@media screen and (max-width: $mobile) {
							width: 6vw;
							height: 6vw;
						}

						&.fb {
							background: url('../../img/FB_Icon_Link_Footer.png') no-repeat;
							background-size: contain;
						}
						&.ig {
							background: url('../../img/IG_Icon_Link_Footer.png') no-repeat;
							background-size: contain;
						}
						&.yt {
							background: url('../../img/Youtube_Icon_Link_Footer.png') no-repeat;
							background-size: contain;
						}
					}
				}
			}
		}

		.bottom_footer {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			margin-top: 2vw;

			img {
				margin-right: 1vw;
				height: 2vw;

				@media screen and (max-width: $mobile) {
					height: 6vw;
				}
			}
			span {
				margin-left: 105px;
			}
			.c-text {
				color: #b3b3b3;
				font-size: 0.75vw;
				line-height: 1.5;
				text-align: left;

				@media screen and (max-width: $mobile) {
					font-size: 2.5vw;
					line-height: 1.2;
					width: 80%;
					margin-left: 2vw;
				}
			}
		}
	}
}
