// #newsdetail{
// 	.body{
// 		padding-top: 2.5vw;
// 		padding-bottom: 2.5vw;

// 		.newsbody{
// 			text-align: left;
// 			margin-top: 2vw;
// 		}
// 	}
// }

#news{
	.detail{
		.news_list{
			background: #fff;
			padding: 3vw;

			.box_news{
				display: flex;
				color: #6b6b6b;
				margin-bottom: 2vw;
				cursor: pointer;

				.box{
					width: 40%;
					position: relative;
					height: 35vh;
					background: #000;
					overflow: hidden;

					img{
						position: relative;
						top: 50%;
						transform: translateY(-50%);
					}

					&.details{
						background: transparent;
						width: 60%;
						padding: 0 2vw;
						text-align: left;
						position: relative;

						// border-bottom: 2px solid #e0e0e0;

						.head{
							font-family: Bold;
							text-transform: uppercase;
							margin-bottom: .5vw;
						}
						.highlight{
							// height: 16vh;
							overflow: hidden;
							text-overflow: ellipsis;
							margin-bottom: .5vw;
							// @media screen and (min-width: 1367px){
							// 	height: 12vh;
							// }
						}

						.by{
							width: 100%;
							display: flex;
							align-items: center;
							margin-bottom: .5vw;

							p{
								width: calc(100%/2);

								&:last-child{
									text-align: right;
								}
							}
						}

						// .date{
						// 	position: absolute;
						// 	bottom: 5%;
						// 	// @media screen and (min-width: 1367px){
						// 	// 	bottom: 1vw;
						// 	// }
						// }

						&:after{
							content: '';
							width: 92%;
							margin: 0 auto;
							height: 2px;
							background: #e0e0e0;
							position: absolute;
							bottom: 0;
						}
					}

					img{
						position: relative;
						top: 50%;
						transform: translateY(-50%);
					}
				}
			}
		}

		@media screen and (max-width: $mobile){
			padding: 0;

			.news_list{
				padding: 5vw ;

				.box_news{
					display: block;
					margin-bottom: 8vw;

					.box{
						width: 100%;
						height: auto;

						&.details{
							width: 100%;
							margin: 0;
							padding: 5vw 0 4vw;

							.head{
								margin-bottom: 2vw;
							}

							.highlight{
								height: auto;
								margin-bottom: 2vw;
							}

							// .date{
							// 	position: relative;
							// 	bottom: 0;
							// }

							&:after{
								width: 100%;
							}
						}

						img{
							position: relative;
							top: 0;
							transform: initial;
						}
					}
				}
			}
		}
	}

	.paginate{
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 2vw auto 0;
		list-style-type: none;
		padding: 0;

		li{
			margin: 0 .25vw;
			padding: .35vw .5vw;
			border-bottom: 0;
			background: #292929;
			color: #fff;
			width: 2.5vw;
			text-align: center;
			cursor: pointer;
			transition: all .2s ease-in-out;

			&.active, &:hover{
				background: #e0e0e0;
				color: #000;
			}

			@media screen and (min-width: $medium) {			
				width: 2vw;
				font-size: .7vw;
			}

		}

		@media screen and (min-width: $medium) {			
			margin: 1vw auto 0;
		}

		@media screen and (max-width: $mobile){
			li{
				width: 8vw;
				margin: 0 .8vw;

			}
		}
	}
}