.navbar {
  position: fixed;
  width: 100%;
  z-index: 5;
  padding: 0;
  background-color: black;
  .logo_asl {
    width: 7vw;
    margin-right: 3vw;
  }
  .nav-cont {
    // height: 11vw;
    background: transparent;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    height: 6vw;
    border: 0;

    &.home {
      background: black;
    }

    @media screen and (max-width: $mobile) {
      height: 17vw;
    }

    @media screen and (min-aspect-ratio: 13/9) and (max-width: $mobile) {
      height: 10vw;
    }

    .download {
      position: absolute;
      right: 5vw;

      a {
        text-transform: uppercase;
        border: 2px solid #fff;
        padding: 1.5vw 3vw;
        border-radius: 25px;
        color: #fff;
        text-decoration: none;
        // font-family: GothamMedium;
        font-weight: bold;
        cursor: pointer;
        transition: all 0.1s ease-in-out;

        &:focus,
        &:hover {
          outline: 0;
        }
        &:hover {
          background: #fff;
          color: #000;
        }
      }

      @media screen and (min-width: $mobile) {
        display: none;
      }
    }

    .hamburger {
      display: none;

      @media screen and (max-width: $mobile) {
        display: inline-block;
        position: absolute;
        padding: 0;
        left: 5vw;

        .hamburger-box {
          top: 2px;
          width: 22px;
        }

        .hamburger-inner,
        .hamburger-inner::after,
        .hamburger-inner::before {
          width: 20px;
          background-color: #fff;
          height: 3px;
        }

        .hamburger-inner {
          &::before {
            top: -7px;
          }

          &::after {
            bottom: -7px;
          }
        }

        &.hamburger--spin.is-active {
          // top: 2vw;
          // right: 21vw;
          z-index: 6;
        }

        &.hamburger--spin.is-active .hamburger-inner::after {
          bottom: 0;
        }
      }
    }

    .nav-menu {
      position: absolute;
      padding: 0;
      right: 0;
      width: 100%;
      height: 100%;

      ul {
        display: flex;
        justify-content: center;
        align-items: center;
        list-style-type: none;
        margin: 0;
        padding: 0;
        font-family: Bold;
        // font-weight: bold;
        // font-size: 1.2vw;
        height: 100%;

        a {
          color: #fff;
          text-decoration: none;
          text-align: center;
          margin-right: 4vw;
          height: 50%;
          display: table;
          // font-size: 1.3vw;

          &:hover {
            text-shadow: 0 0 10px #fff;
          }

          li {
            display: table-cell;
            vertical-align: middle;
            // min-width: 7vw;
            border-bottom: 3px solid transparent;
            padding: 0 1vw;
            text-transform: uppercase;

            &.active {
              border-bottom: 3px solid #3398fe;
            }

            @media screen and (max-width: $medium) {
              // min-width: 9vw;
            }
          }

          &:last-child {
            margin-right: 0;
          }

          &:focus {
            outline: 0;
          }
        }
      }

      @media screen and (max-width: $mobile) {
        display: none;
      }
    }

    &.active {
      .download {
        z-index: -1;
      }
    }
  }

  .menu {
    position: absolute;
    top: 0;
    left: -65vw;
    width: 65vw;
    background: #0f0f0f;
    padding: 15vw 8vw 10vw;
    transition: all 0.2s ease-in-out;
    color: #fff;
    height: 100vh;
    overflow-y: auto;
    z-index: 5;
    text-align: left;

    @media screen and (min-aspect-ratio: 13/9) {
      padding: 2vw 3vw 5vw;
      width: 45vw;
      height: 86vh;
    }

    .logo {
      width: 7vw;

      @media screen and (max-width: $mobile) {
        width: 50%;
      }
    }

    &.active {
      left: 0;
    }

    > div,
    > ul,
    > img {
      margin-bottom: 3vw;
    }

    ul {
      margin: 1vw auto;

      a {
        text-decoration: none;
        color: #fff;

        &:focus {
          outline: none;
        }
      }

      padding: 0;

      li {
        // padding-bottom: 2vw;
        list-style: none;
        font-size: 4vw;
        cursor: pointer;
        font-family: Regular;
        padding: 2vw 2vw 4vw;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        text-transform: uppercase;
        margin-bottom: 2vw;
        .list_menu {
          .trigger_collapse {
            // display: block;
            position: relative;

            img {
              position: absolute;
              width: 1.3vw;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
              transition: all 0.3s ease-in-out;
            }

            &[aria-expanded='true'] {
              img {
                transform: translateY(-50%) rotate(90deg);
              }
            }
          }

          .collapse_content {
            padding: 2vw 5vw;
            li {
            }
          }
        }

        @media screen and (min-aspect-ratio: 13/9) {
          font-size: 2vw;
          padding-bottom: 1vw;

          .list_menu {
            .trigger_collapse {
              img {
                width: 0.8vw;
              }
            }
            .collapse_content {
              padding: 1vw 5vw 0;
            }
          }
        }
      }
    }

    .socmed {
      img {
        width: 6vw;
        filter: invert(1);
        margin-right: 3vw;
      }
    }

    p {
      font-size: 2.5vw;
      font-style: italic;
    }
  }
}
