.box_schedule {
  margin: 1vw auto;

  @media screen and (max-width: $mobile) {
    margin: 0 auto 4vw;
  }

  .handle_nav {
    width: 100%;
    position: relative;

    .handle {
      background: url("../../img/scroll.png") no-repeat;
      background-size: contain;
      width: 2.5vw;
      height: 1.5vw;
      position: absolute;
      top: 25%;
      left: 0;
      z-index: 2;
      transform: rotate(90deg) translateY(-25%);
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      filter: invert(0.5);

      &.next-btn {
        transform: rotate(-90deg);
        left: initial;
        right: 0;
      }

      &:hover {
        opacity: 0.5;
      }

      @media screen and (max-width: $mobile) {
        filter: invert(0.5);
        width: 6vw;
        height: 4vw;
        top: 30%;
      }
    }
  }
}

.nav {
  display: block;
  border-bottom: 3px solid transparent;
  justify-content: center;
  overflow-x: scroll;
  overflow-y: hidden;
  // width: 100%;
  white-space: nowrap;
  width: 93%;
  margin: 0 auto;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  .nav-item {
    margin: 0;
    width: 20%;
    text-align: center;
    display: inline-block;

    .nav-link {
      // color: #fff;
      color: #6b6b6b;
      text-transform: uppercase;
      font-family: Bold;
      border-bottom: 3px solid transparent;
      font-size: 1.2vw;

      &.active,
      &:hover {
        background: transparent;
        border: 0;
        border-bottom: 3px solid #3399ff;
        color: #3399ff;
      }

      &:focus {
        outline: 0;
      }
    }
  }

  @media screen and (max-width: $mobile) {
    border-bottom: 2px solid transparent;
    width: 85%;

    .nav-item {
      width: 35%;
      .nav-link {
        font-size: 4vw;
        color: #6b6b6b;
        // border-bottom: 2px solid transparent;

        &.active,
        &:hover {
          // border-bottom: 2px solid #3399ff;
        }
      }
    }
  }
}

.tab-content {
  margin-top: 1vw;
}

.detail_schedule {
  background: #fff;

  @media screen and (max-width: $mobile) {
    background: #e9e9e9;
  }

  .time {
    background: #002b9e;
    padding: 0.5vw 0;
    font-family: Regular;

    &.mobile {
      display: none;
    }

    @media screen and (max-width: $mobile) {
      padding: 1.5vw 0;
      &.desktop {
        display: none;
      }

      &.mobile {
        display: block;
      }
    }

    p {
      color: #fff;
      text-transform: uppercase;

      span {
        font-weight: bold;
      }

      .mobiletime {
        display: none;
        font-weight: normal;
        border-left: 1px solid #fff;
        padding-left: 2vw;
        margin-left: 1vw;

        @media screen and (max-width: $mobile) {
          display: inline-block;
        }
      }
    }
  }

  .detail {
    color: #6b6b6b;
    font-family: SemiBold;
    top: 0;

    .box_detail {
      display: flex;
      align-items: center;
      width: 100%;
      border-bottom: 2px solid #c9c9c9;

      .team {
        width: 42%;
        display: flex;
        align-items: center;
        .logo {
          width: 40%;
          height: 100%;
          // background: url("../../img/teams/small_logo/dg.jpg") no-repeat center
          //   center;
          // background-size: cover;

          img {
            opacity: 0;
          }
        }
        .name {
          width: 50%;
        }

        .score {
          width: 10%;
        }
      }
      .vs {
        width: 15%;
        margin: 0 1vw;

        p {
          line-height: 1.2;

          &:first-child {
            font-family: Bold;
            font-size: 1.7vw;
          }
        }
      }

      @media screen and (max-width: $mobile) {
        display: block;
        border: 0;
        margin-bottom: 5vw;
        .team {
          width: 100%;
          border-bottom: 1px solid #a5a5a5;
          background: #fff;

          .logo {
            width: 25%;
            height: 16vw;
          }
          .name {
            width: 55%;
            margin: 0 1vw 0 2vw;
            text-align: left;
          }

          .score {
            width: 20%;
          }

          &.away {
            flex-direction: row-reverse;
            justify-content: end;
          }
        }
        .vs {
          display: none;
        }
      }
    }
  }
}

#schedule {
  min-height: 100vh;
  color: white;
  background-color: #aab5e0;

  .mobile {
    display: none;
  }

  .schedule-wrapper {
    &.detail {
      top: -6vw;
    }

    .box_schedule {
      border-top: 2px solid #fff;
      padding-top: 0.5vw;

      .handle {
        filter: invert(0);
      }

      // .nav-link {
        // color: #fff;
      // }

      .tab-content {
        margin-top: 2vw;
      }

      @media screen and (max-width: $mobile) {
        .tab-content {
          margin-top: 5vw;
        }
        .handle {
          filter: invert(0.5);
        }

        .nav-link {
          color: #6b6b6b;
        }
      }
    }

    .middle-explanation {
      text-align: center;
      padding: 5vw 10vw;

      p {
        // font-size: 1.5vw;
        color: #414141;
        margin-top: 2vw;
      }

      .button-check {
        text-transform: uppercase;
        font-size: 2vw;
        color: white;
        background-color: #4ab0e4;
        margin-bottom: 5vw;
        padding: 1.2vw 6vw;
      }
    }

    .last-table-wrapper {
      // TABLE BAGIAN BAWAH

      .last-week-title {
        color: #002b9e;
        padding-left: 5vw;
        padding-bottom: 1.5vw;
        font-size: 3vw;
        font-family: Bold;
      }

      .detail_schedule {
        .time {
          background: #535353;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  #schedule {
    .schedule-wrapper {
      background-color: #e8e8e8;
      padding: 0 !important;

      &.detail {
        top: 0;
      }

      .desktop {
        display: none;
      }

      .mobile {
        display: block;
      }

      .middle-explanation {
        padding: 6vw 5vw;
        margin-top: 4vw;

        .button-check {
          padding: 3vw 12vw;
          font-size: 4vw;
          margin-bottom: 7vw;
        }

        p {
          font-size: 4vw;
          // text-align: left;
        }
      }

      .last-table-wrapper {
        .last-week-title {
          font-size: 7vw;
        }
      }
    }
  }
}
