.sec6 {
	background: #e9e9e9;

	.head_title {
		&:after {
			background: transparent;
		}
	}

	.video {
		padding-top: 2vw;
		padding-bottom: 4vw;

		@media screen and (max-width: $mobile) {
			padding-top: 4vw;
			padding-bottom: 8vw;
		}
		.box-video {
			background: grey;
			display: flex;

			@media screen and (max-width: $mobile) {
				flex-direction: column;
			}

			.container {
				display: flex;
				justify-content: center;
				align-items: center;
				max-width: none;
				// align-items: center;
				padding: 4vw 2vw;
				// padding-top: 4vw;
				// padding-bottom: 4vw;

				.box {
					width: 50%;

					&.vid {
						height: 25vw;
						background: #000;

						iframe {
							height: 100%;
						}
					}

					&.text {
						width: 0%;
						text-align: left;
						padding-left: 2vw;
						color: #fff;
						line-height: 1.5;

						h3 {
							text-transform: capitalize;
							font-family: Bold;
							font-size: 2vw;
							line-height: 1;
							margin-bottom: 1vw;
							line-height: 1.2;
						}

						ul {
							padding: 1vw;
						}
					}
				}
				@media screen and (max-width: $mobile) {
					display: block;

					.box {
						width: 100%;

						&.vid {
							height: 35vh;
							margin-bottom: 5vw;

							iframe {
								height: 100%;
							}
						}

						&.text {
							width: 100%;
							padding: 0 2vw;
							h3 {
								font-size: 7vw;
							}
							p {
								text-align: justify;
							}

							ul {
								padding: 2vw 4vw;
							}
						}
					}
				}
			}
		}
	}
}
