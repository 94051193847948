@import url("index.scss");

.sec1 {
	height: 112vh;
	// background: url("../../img/ASL_Bg_Desktop.jpg") no-repeat top center;
	background-size: cover;
	// background-position-y: -27%;
	// background-position-y: -215%;
	position: relative;
	.landing {
		// text-align: left;
		position: absolute;
		top: 25%;
		left: 0%;
		width: 78.5%;
		// transform: translateY(-50%);

		@include tablet-view() {
			top: 36%;
			width: 66%;

			@media screen and (orientation: portrait) {
				top: 57.5%;
				width: 100%;
			}
		}

		img {
			// width: 10vw;
			width: 20vw;
			// width: 65%;
		}

		.logoaov {
			width: 18%;
		}

		.headline {
			margin: 0 auto;
			p {
				color: #0060b7;
				text-transform: uppercase;
				line-height: 1;
				font-family: Regular;
				font-size: 2.5vw;
				margin-bottom: 2vw;

				&:first-child {
					margin-bottom: 0.5vw;
					color: #002b9f;
					font-size: 3.5vw;
					font-family: Bold;
					width: 90%;
				}
			}
		}

		.sub-headline {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 1vh;

			.btn_click {
				width: 20vw;
				display: grid;
				place-items: center;

				@include tablet-view() {
					width: 30vw !important;

					@media screen and (orientation: portrait) {
						width: 60vw !important;
					}
				}

				div {
					padding: 1vw 0;
				}
			}

			@media screen and (max-width: $mobile) {
				align-items: center;

				.btn_click {
					width: 85%;

					div {
						padding: 1vh 0vw;
					}
				}
			}
			a:nth-of-type(2) {
				width: 20vw;
				@media screen and (max-width: $mobile) {
					width: auto;
				}
			}
			.sponsor-container {
				display: flex;
				flex-direction: column;
				width: 20vw;
				align-items: center;
				gap: 1vh;

				@include tablet-view() {
					width: 30vw !important;

					@media screen and (orientation: portrait) {
						width: 60vw !important;
					}
				}

				@media screen and (max-width: $mobile) {
					margin-top: 2%;
					width: 85%;
					flex-direction: column;
					gap: 1vh;
				}

				.featured-sponsor {
					flex: 1;

					img {
						width: 100%;
						margin: unset;
					}

					// .featured-sponsor {
					// 	// height: 9.9vh;
					// 	// width: auto;
					// 	// width: 99%;
					// 	// margin: 0;
					// 	// @media screen and (orientation: portrait) {
					// 	// 	height: 13.6vh;
					// 	// }
					// 	// @media screen and (orientation: landscape) and (max-aspect-ratio: 3/2) {
					// 	// 	height: 6.6vh;
					// 	// }
					// 	// @media screen and (orientation: landscape) and (min-aspect-ratio: 2/1) and (max-width: 1000px) {
					// 	// 	height: 10.8vh;
					// 	// }
					// 	// @media screen and (orientation: portrait) and (max-aspect-ratio: 1/2) {
					// 	// 	height: 11.1vh;
					// 	// }
					// }
				}
			}
		}

		.btn_click {
			border-radius: 0px;
			font-size: 1vw;
		}
	}

	@media screen and (max-width: $mobile) {
		// background: url("../../img/ASL_Bg_Mobile.jpg") no-repeat top center;
		background-size: cover;
		// background-position-y: -30%;
		height: 100vh;

		// .container {
		// display: flex;
		// flex-direction:column;
		// justify-content: center;
		// height: 100vh;
		// }

		.landing {
			text-align: center;
			width: 100%;
			left: 0;
			right: 0;
			margin: 0 auto;
			top: 75%;
			transform: translateY(-75%);
			// bottom: 0%;
			// bottom: -5vw;
			// position: relative;
			// flex-shrink: 0;
			// justify-content: space-between;

			img {
				// width: 35%;
				// margin-bottom: 7vw;
				width: 75%;
				margin: 0 auto 7vw;
			}

			.logoaov {
				display: none;
				margin: 0 auto;
				width: 45%;
			}

			.headline {
				// text-align: left;
				margin: 1vw auto 7vw;
				padding: 0 4vw;

				p {
					line-height: 1.2;
					font-size: 8vw;
					margin-bottom: 2vw;

					&:first-child {
						font-size: 10vw;
						width: initial;
					}
				}
			}

			.btn_click {
				border-radius: 0;
				font-size: 5vw;
			}
		}

		.landing-sponsored {
			top: 90%;
			transform: translateY(-100%);
		}
	}

	// @media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0){
	//     /*
	//       Define here the CSS styles applied only to Safari browsers
	//       (any version and any device)
	//      */
	// 	min-height: 90vh;

	// }
}

.sponsor {
	//display: flex;
	justify-content: center;
	align-items: center;
	background: white;
	color: black;
	position: absolute;
	bottom: 0;
	width: 100%;
	left: 0;
	right: 0;
	margin: 0 auto;
	// padding: .3vw 0;
	//background: url(../../img/Footer_Web.jpg) center no-repeat;
	background-size: 100%;
	height: 4.5vw;

	@media screen and (max-width: $mobile) {
		justify-content: start;
		padding: 1% 3%;

		// background: url(../../img/mobile/Footer_Mobile.jpg) center no-repeat;
		background-size: 100%;
		height: 9vw;
	}

	p {
		text-transform: capitalize;
		// margin: 0 1vw;
	}

	.list_sponsor {
		gap: 1%;
		padding-right: 1%;
		display: flex;
		justify-content: center;
		align-items: center;
		// margin-left: 1vw;
		height: 100%;

		a {
			img {
				width: 15vw;
				margin: 0;

				&.xl {
					width: 10vw;
				}

				@media screen and (max-width: $mobile) {
					width: 25vw;
					&.xl {
						width: 30vw;
					}
				}
			}
		}
	}

	.official_sponsor_text {
		font-size: 24px;
		@media screen and (max-width: $mobile) {
			font-size: 18px;
		}
	}
	.sponsor {
		display: flex;
		justify-content: center;
		align-items: center;
		// background: #fff;
		color: #000;
		position: absolute;
		bottom: 0;
		width: 100%;
		left: 0;
		right: 0;
		margin: 0 auto;
		// padding: .3vw 0;
		//background: url(../../assets/img/Footer_Web.jpg) center no-repeat;
		background-size: 100%;
		height: 4.5vw;

		@media screen and (max-width: $mobile) {
			justify-content: start;
			padding: 1% 3%;

			// background: url(../../assets/img/mobile/Footer_Mobile.jpg) center no-repeat;
			background-size: 100%;
			height: 9vw;
		}

		p {
			text-transform: capitalize;
			// margin: 0 1vw;
		}

		.list_sponsor {
			gap: 1%;
			padding-right: 1%;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-left: 1vw;

			a {
				img {
					width: 6vw;

					&.xl {
						width: 10vw;
					}

					@media screen and (max-width: $mobile) {
						width: 17vw;
						&.xl {
							width: 30vw;
						}
					}
				}
			}
		}
	}
}
