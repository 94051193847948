.sec4 {
  background: #e9e9e9;

  .head_title {
    &:after {
      background: transparent;
    }
  }

  .news {
    padding-top: 2vw;
    padding-bottom: 4vw;

    .box_news {
      display: flex;
      // align-items: center;
      width: 100%;
      .box {
        width: calc(100% / 2);
        height: 40vh;

        &.slider {
          background: #000;

          .slick-slider,
          .slick-list,
          .slick-track {
            height: inherit;
          }

          .slick-slide {
            position: relative;

            div {
              position: relative;
              top: 50%;
              transform: translateY(-50%);

              a {
                img {
                  // opacity: 0;
                }
              }
            }
          }

          a {
            cursor: pointer;
          }
          .slick-arrow {
            z-index: 1;

            &:before {
              content: '';
            }

            &.slick-prev {
              left: 0.75vw;
              background: url('../../img/arrow-prev.png') no-repeat;
              background-size: contain;
            }

            &.slick-next {
              background: url('../../img/arrow-next.png') no-repeat;
              background-size: contain;
              right: 0.1vw;
            }
          }

          .slick-dots {
            bottom: 1vw;

            li {
              width: 15px;
              height: 15px;
              button {
                width: 15px;
                height: 15px;

                &:before {
                  color: #fff;
                  width: 15px;
                  height: 15px;
                }
              }
            }
          }
        }

        &.news_content {
          padding-left: 1vw;
          .nav {
            overflow: hidden;
            margin: 0;
            width: 100%;
            .nav-item {
              width: calc(100% / 3);

              .nav-link {
                border-bottom: 3px solid #d5d5d5;
                text-transform: capitalize;

                &.active,
                &:hover {
                  border-bottom: 3px solid #3399ff;
                }
              }
            }
          }

          .newest {
            text-align: left;
            display: flex;
            padding: 0.5vw 0;
            align-items: center;
            margin-bottom: 1vw;
            cursor: pointer;
            border-bottom: 2px solid #e0e0e0;
            color: #616161;

            .tag {
              width: 18%;
              text-transform: uppercase;
              background: grey;
              margin: 0 1vw;
              border-radius: 25px;
              text-align: center;
              span {
                padding: 0.25vw 0;
                color: #fff;
                font-size: 0.8vw;
              }
            }

            .name {
              width: 79%;
              text-transform: capitalize;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              padding: 0 0.8vw;
              // text-align: left;
            }

            .date {
              width: 20%;
            }

            &:last-child {
              margin-bottom: 0;
            }

            &:hover {
              color: #000;
            }
          }
        }
      }

      @media screen and (max-width: $mobile) {
        display: block;

        .box {
          width: 100%;

          &.slider {
            margin-bottom: 4vw;
            .slick-arrow {
              width: 5vw;
              height: 5vw;

              &.slick-prev {
                left: 2.2vw;
              }
            }

            .slick-dots {
              bottom: 4vw;
            }
          }

          &.news_content {
            .tab-content {
              min-height: 50vw;
            }
            .newest {
              margin-bottom: 2vw;
              padding: 1vw 0;

              .tag {
                width: 20%;
                span {
                  padding: 0.5vw 0;
                  font-size: 3.2vw;
                }
              }

              .name {
                // width: 55%;
              }
            }
          }
        }
      }
    }
  }
}
