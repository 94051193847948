.sec2 {
  background: url('../../img/Bg_Banner_Section2_Rewards_Landing.jpg') no-repeat
    top center;
  background-size: cover;

  .container {
    padding-top: 5vw;
    padding-bottom: 5vw;
    text-align: left;
    .info-reward {
	    display:none;
      position: relative;
      color: #fff;

      &:before {
        content: '';
        width: 0.7vw;
        height: 100%;
        position: absolute;
        background: #fff;
        display: inline-block;
      }

      p {
        padding-left: 2vw;
        font-size: 2.5vw;
        text-transform: uppercase;

        &:first-child {
          font-family: Bold;
          font-size: 3vw;
        }
      }
    }
  }

  @media screen and (max-width: $mobile) {
    background: url('../../img/mobile/Bg_Banner_Section2_Rewards_Landing_Mobile.jpg')
      no-repeat top center;
    background-size: cover;

    .container {
      .info-reward {
        &:before {
          content: '';
          width: 1.5vw;
          height: 100%;
          position: absolute;
          background: #fff;
          display: inline-block;
        }

        p {
          padding-left: 4vw;
          font-size: 5vw;
          font-family: Regular;

          &:first-child {
            font-size: 9vw;
          }
        }
      }
    }
  }
}
