#home{
	background-color: #fff;

	@import '../home/landing';
	@import '../home/prize';
	@import '../home/infomatch';
	@import '../home/news';
	@import '../home/checkteams';
	@import '../home/video';

	.head_title{
		color: #002b9f;
		text-transform: capitalize;
		font-family: Bold;
		font-size: 3vw;
		position: relative;
		padding-bottom: 1vw;
		text-align: left;
		padding: 0 2vw 1vw;

		a{
			font-size: 1vw;
			font-family: Regular;
			position: absolute;
			right: 2vw;
			top: 50%;
			transform: translateY(-50%);
			text-transform: uppercase;
			color: #3399ff;
		}

		&:after{
			content: '';
			width: 100%;
			height: 4px;
			background: #707070;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			margin: 0 auto;
		}

		@media screen and (max-width: $mobile){
			font-size: 7vw;
			padding: 0 4vw;
			a{
				font-size: 3.5vw;
				position: relative;
				bottom: 5vw;
				right: initial;
				left: 0;
			}

			&:after{
				height: 1px;
			}
		}
	}
	
}