$mobile: 767px;
$medium: 1366px;

@import 'partial/fonts';
@import 'partial/floating';
@import 'partial/landscape';
@import 'partial/navbar';
@import 'partial/footer';

@mixin mobile-view() {
  @media (max-width: 480px) and (orientation: portrait) {
    @content;
  }
}

@mixin tablet-view() {
  @media screen and (min-device-width: 768px) and (max-device-width: 1366px) and (max-device-aspect-ratio: 15/9) {
    @content;
  }
  @media screen and (min-device-width: 768px) and (max-device-width: 1366px) and (device-aspect-ratio: 4/3) {
    @content;
  }
}

@mixin desktop-view() {
  @media screen and (min-width: 961px) {
    @content;
  }
}

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: Regular;
  color: #000;
  // color: #fff;
  overflow-x: hidden;
}

#noMatch{
  width: 100%;
  height: 55vh;
  padding-top: 6vw;
  align-items: center;
  justify-content: center;
  display: flex;
}

@media screen and (max-width: $mobile) {
  #garena_top_bg02 {
    display: none !important;
  }
}

.wrapper {
  text-align: center;
  min-height: 100vh;
  font-size: 1vw;
  background: #e9e9e9;

  @media screen and (max-width: $mobile) {
    font-size: 3.8vw;
  }

  p {
    margin: 0;
  }

  a:hover,
  a:focus {
    outline: 0;
    text-decoration: none;
    border: 0;
  }

  .content,
  #footer {
    @media screen and (min-width: $mobile) and (max-width: $medium) {
      .container {
        padding-left: 7vw;
        padding-right: 7vw;
      }
    }
  }

  .content_wrapper {
    padding: 6vw 0;

    @media screen and (max-width: $mobile) {
      padding: 17vw 0 0;
    }
  }
}

.btn_click {
  background: #3399fe;
  display: inline-block;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  .buttons {
    padding: 1vw 4vw;
    color: #fff;
    text-transform: uppercase;
    font-family: SemiBold;
  }

  &:hover {
    background: #0080fe;
  }

  @media screen and (max-width: $mobile) {
    .buttons {
      padding: 4vw 15vw;
    }
  }
}

.custom-overlay {
  // background: rgba(36, 123, 160, 0.7);
}
.custom-modal {
  background: #000;
  width: 100%;
  color: #fff;
  font-family: KanitRegular;
  text-align: center;
  padding: 5vw;

  @media screen and (orientation: landscape) {
    width: 60%;
    padding: 3vw;
    max-height: 90vh;
    overflow-y: auto;
  }
}

.custom-close {
  cursor: pointer;
  top: 3vw;
  right: 3vw;
  background: url('../img/Btn_Close.png') no-repeat;
  background-size: contain;
  width: 4vw;
  height: 4vw;
  transition: all 0.1s ease-in-out;

  @media screen and (orientation: landscape) {
    width: 1vw;
    height: 1vw;
    top: 1.5vw;
    right: 1.5vw;
  }

  svg {
    opacity: 0;
  }

  &:focus {
    outline: 0;
  }

  &:hover {
    opacity: 0.6;
  }

  &.close-rwd {
    display: none;
  }
}

@import 'pages/home';
@import 'pages/teams';
@import 'pages/mechanism';
@import 'pages/schedule';
@import 'pages/news';
@import 'pages/standings';
